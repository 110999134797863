import gql from 'graphql-tag'


export const LIST_UNIT = (templateType) => gql`query LIST_UNIT ($unitType: String!, $q: FilterInput) {
  units: listUnit${templateType} (unitType: $unitType, q: $q) {
    id name isDefault
  }
}`

export const DETAIL_UNIT = (templateType) => gql`query DETAIL_UNIT ($unitType: String!, $unitId: Int!) {
  unit: detailUnit${templateType} (unitType: $unitType, unitId: $unitId) {
    id name isDefault
  }
}`

export const CREATE_UNIT = (templateType) => gql`mutation CREATE_UNIT ($unitType: String!, $input: Unit${templateType}Input!) {
  createUnit: createUnit${templateType} (unitType: $unitType, input: $input) {
    id name isDefault
  }
}`

export const UPDATE_UNIT = (templateType) => gql`mutation UPDATE_UNIT ($unitType: String!, $unitId: Int!, $input: Unit${templateType}Input!) {
  updateUnit: updateUnit${templateType} (unitType: $unitType, unitId: $unitId, input: $input) {
    id name isDefault
  }
}`

export const DESTROY_UNIT = (templateType) => gql`mutation DESTROY_UNIT ($unitType: String!, $unitId: Int!) {
  destroyUnit: destroyUnit${templateType} (unitType: $unitType, unitId: $unitId) {id}
}`
